import React from 'react';
import logo from '../assets/images/Jummbo_PrimaryLogo_Lime.png';  

const AdminHeader = () => {  
    return (  
        <div className="admin-titlebar">  
            <img src={logo} alt="Logo" />  
        </div>  
    );  
};  

export default AdminHeader;
