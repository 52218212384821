import React from 'react';

const WebHeader = () => {
    return (
        <React.Fragment>
            <div className="admin-titlebar">
                <span>Jummbo</span>
                <span><a href="/login">Login</a></span>
            </div>
        </React.Fragment>
    );
};

export default WebHeader;
