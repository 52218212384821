import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap'; // Import Bootstrap components
import { useDispatch, connect } from 'react-redux';
import { refreshJobs } from '../redux/actions'; // Import your action creator
import { fetchData } from '../helpers/api'; // Import the fetchData function
import { ImSpinner9 } from "react-icons/im";
import { updateUserBio } from '../redux/actions';

const Step3CompanyBrief = ({ id, state }) => {
    // console.log("job is " + JSON.stringify(id))
    // console.log("state is " + JSON.stringify(state))
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    // Set the state using the initial values
    const [generatingPreviews, setGeneratingPreviews] = useState(false)


    const Spinner = () => (
        //used to block the screen when async jobs are running
        <div className="spinner-overlay">
            <ImSpinner9 className="spinner-icon" />
        </div>
    );

    async function handleUpdateTextGenSetting(event) {
        setWaiting(true);
        setGeneratingPreviews(true)
        event.preventDefault(); // Prevent the default form submission behavior

        // Check if specialInstructions field is not empty
        if (event.target.elements.specialInstructions.value.trim()) {
            var specialInstructionsValue = event.target.elements.specialInstructions.value;
        } else {
            var specialInstructionsValue = state.jobs.find((job) => job._id === id)?.textGen.specialInstructions;
        }

        const updatedJobs = state.jobs.map((job) => {
            if (job._id === id) {
                // Create a new object with updated textGen properties
                const updatedTextGen = {
                    ...job.textGen, // Preserve existing properties
                    bio: state.user.bio,
                    language: state.user.language,
                    specialInstructions: specialInstructionsValue
                };

                // Create a new job object with the updated textGen
                return {
                    ...job,
                    textGen: updatedTextGen,
                };
            }
            return job;
        });
        await dispatch(refreshJobs(updatedJobs));


        //update database in background
        updatedJobs.map(async (job) => {
            if (job._id === id) {
                // Create a new object with updated textGen properties
                let url1 = process.env.REACT_APP_WFE_URL + "/database/update"
                await fetchData(url1, job, { "jwtToken": state.user.jwt })
                let url2 = process.env.REACT_APP_WFE_URL + "/jobs/generatepreviews"
                const alljobs = await fetchData(url2, job, { "jwtToken": state.user.jwt })
                dispatch(refreshJobs(alljobs))
                setGeneratingPreviews(false)
                setWaiting(false);
            }
        });

    }

    return (
        <React.Fragment>
            {waiting && <Spinner />}

            <Form onSubmit={handleUpdateTextGenSetting}>
                {/* Special Instructions */}
                <Form.Group controlId="specialInstructions">
                <br></br><br></br>
                    <h4>Special Instructions</h4>
                    <div className="form-tip">Enter special instructions you wish Jummbo to refer to when drafting emails and call scripts.
                        Examples:
                        <li>ensure a specific case study is always mentioned, including the URL to it</li>
                        <li>request the call to action is always within 1 week</li>
                        <li>always offer a 14 day free trial</li>
                    </div>
                    <br></br>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        defaultValue={state.jobs.find((job) => job._id === id)?.textGen.specialInstructions}
                    />
                </Form.Group>

                <br></br>
                {generatingPreviews ? (
                    <Button disabled>Generating...</Button>
                ) : (
                    <Button variant="primary" type="submit">
                        Generate Previews
                    </Button>
                )}
            </Form >
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {

    return {
        state: state.myReducer
    };
};


export default connect(mapStateToProps)(Step3CompanyBrief);
