import React, { useState } from 'react';  
import { Container, Row, Col, Button, Form } from 'react-bootstrap';  
import { useNavigate } from 'react-router-dom';  
import { useDispatch, connect } from 'react-redux';  
import { refreshJobs, refreshSingleSite, setJWT } from '../redux/actions';  
import logo from '../assets/images/Jummbo_PrimaryLogo_Forest.png';  
  
const CreateAccountPage = () => {  
  const navigate = useNavigate();  
  const dispatch = useDispatch();  
  const [email, setEmail] = useState('');  
  const [emailValid, setEmailValid] = useState(false);  
  const [companyWebsite, setCompanyWebsite] = useState('');  
  const [websiteValid, setWebsiteValid] = useState(true);  
  const [createUserResponse, setcreateUserResponse] = useState(null);  
  const [isSubmitting, setIsSubmitting] = useState(false);  
  
  const handleEmailChange = (e) => {  
    const emailValue = e.target.value;  
    setEmail(emailValue);  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  
    if (emailRegex.test(emailValue)) {  
      setEmailValid(true);  
      const domain = emailValue.substring(emailValue.lastIndexOf("@") + 1);  
      setCompanyWebsite(`www.${domain}`);  
    } else {  
      setEmailValid(false);  
      setCompanyWebsite('');  
    }  
  };  
  
  const validateWebsite = (website) => {  
    const websiteRegex = /^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;  
    return websiteRegex.test(website);  
  };  
  
  const handleSubmit = async (e) => {  
    e.preventDefault();  
  
    if (!validateWebsite(companyWebsite)) {  
      setWebsiteValid(false);  
      return;  
    } else {  
      setWebsiteValid(true);  
    }  
  
    setIsSubmitting(true);  
    try { 
      let url = process.env.REACT_APP_WFE_URL + "/auth/createuser"
      const response = await fetch(url, {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/json',  
        },  
        body: JSON.stringify({ email, companyWebsite }),  
      });  
  
      const data = await response.json();  
      if (data.success === true) {  
        setcreateUserResponse(  
          <React.Fragment>  
            <div className="text-center mb-4">  
              <h3 className="mb-3">Creating your account</h3>  
              <p className="mb-4">Thanks for signing up to Jummbo, we are thrilled to have you on board.</p>  
              <p className="mb-4">We're setting up your account, which may take up to 5 minutes. We'll send you an email containing your login code once we are done.</p>  
            </div>  
          </React.Fragment>  
        );  
      } else {  
        setcreateUserResponse(  
          <React.Fragment>  
            <div className="text-center mb-4">  
              <h3 className="mb-3">Account already exists</h3>  
              <p className="mb-4">It looks like you already have an account.</p>  
              <p className="mb-4">You can <a href="/login">login here</a> to proceed.</p>  
            </div>  
          </React.Fragment>  
        );  
      }  
    } catch (error) {  
      console.error('Error:', error);  
    } finally {  
      setIsSubmitting(false);  
    }  
  };  
  
  return (  
    <div className="createAccountBackground">  
      <Container className="d-flex justify-content-center align-items-center min-vh-100">  
        <div className="createAccountform-container p-5 shadow-sm rounded" style={{ backgroundColor: 'white', maxWidth: '500px', width: '100%' }}>  
          <div className="text-center mb-4">  
            <img src={logo} alt="Jummbo logo" className="img-fluid smallerlogo mb-3" />  
            {createUserResponse ? (  
              null  
            ) : (  
              <React.Fragment>  
                <h3 className="mb-3">Create account</h3>  
                <p className="mb-4">Complete this form to create your Jummbo account.</p>  
              </React.Fragment>  
            )}  
          </div>  
          {createUserResponse ? (  
            createUserResponse  
          ) : (  
            <Form onSubmit={handleSubmit}>  
              <Form.Group as={Row} className="mb-3" controlId="formEmail">  
                <Form.Label column sm="4" className="text-sm-end"><strong>Email address</strong></Form.Label>  
                <Col sm="8">  
                  <Form.Control  
                    type="email"  
                    placeholder="Enter email"  
                    value={email}  
                    onChange={handleEmailChange}  
                  />  
                </Col>  
              </Form.Group>  
              {emailValid && (  
                <Form.Group as={Row} className="mb-3" controlId="formCompanyWebsite">  
                  <Form.Label column sm="4" className="text-sm-end"><strong>Your website</strong></Form.Label>  
                  <Col sm="8">  
                    <Form.Control  
                      type="text"  
                      placeholder="Enter company website"  
                      value={companyWebsite}  
                      onChange={(e) => setCompanyWebsite(e.target.value)}  
                      isInvalid={!websiteValid}  
                    />  
                    <Form.Control.Feedback type="invalid">  
                      Please enter a valid domain (e.g., domain.com or subdomain.domain.com).  
                    </Form.Control.Feedback>  
                  </Col>  
                  <Form.Text className="text-muted">  
                    We use your website to gather information about your organization, so your prospecting efforts are personalized and effective.  
                  </Form.Text>  
                </Form.Group>  
              )}  
              <div className="text-center">  
                <Button variant="primary" type="submit" disabled={isSubmitting}>  
                  {isSubmitting ? 'Creating account...' : 'Signup'}  
                </Button>  
                <br></br><br></br>  
                <p>Already have an account? <a href="/login">Return to login</a>.</p>  
              </div>  
            </Form>  
          )}  
        </div>  
      </Container>  
    </div>  
  );  
};  
  
const mapStateToProps = (state) => {  
  return {  
    fullState: state.myReducer  
  };  
};  
  
export default connect(mapStateToProps)(CreateAccountPage);  
