import React from 'react';
import WebHeader from '../components/WebHeader';
import { Container, Row, Col } from 'react-bootstrap';

const HomePage = () => {
  return (
    <React.Fragment>
      <WebHeader />
      <div>
        <h2>The world's #1 realtime list building and data quality service</h2>
      </div>
      <div>
        <h2>Why us</h2>
        <ul>
          <li>Complete flexibility - build a list to find literally any business anywhere in the world</li>
          <li>No dead link guarantee: Every record is validated for currency, accuracy and completeness.</li>
          <li>Productivity: Jumpstart prospecting with highly targeted AI generated intro emails for every record</li>
          <li>Quality: The industry's deepest profiling and categorisation for segmentation using AI</li>
        </ul>
        <h2>Use cases</h2>
        <ul>
          <li>Fill your sales pipeline with businesses that perfectly align to your ideal customer profile</li>
          <li>Refresh your CRM and marketing data for improved segmentation and marketing outcomes</li>
          <li>Discover new suppliers that offer unique products to help grow and differentiate your business</li>
        </ul>
      </div>
      <div>
        <h2>Examples - Use Plain English to Find What You Are Looking For</h2>
        <ul>
          <li>Small business consultants specialising in eCommerce in regional locations of California outside LA</li>
          <li>Wineries that offer cellar door experiences in South Australia near Adelaide</li>
          <li>Residential plumbers in Ealing, London and surrounding boroughs</li>
          <li>Solar installers in inner city Melbourne Australia that specialise in commercial installations</li>
        </ul>
      </div>
      <div>
        <h2>Features & Fields</h2>
        <ul>
          <li>Natual language search builder to ensure you receive highly targeted lists every time. Find any business with a website, anywhere in the world</li>
          <li>Company Name & website</li>
          <li>Social media links - Insta, facebook, linkedin & youtube links captured</li>
          <li>Employee count captured (where they have a linkedin company page)</li>
          <li>Business phone number, emails and locations</li>
          <li>Linkedin links to key employees, based on a "fuzzy match" of job title for improved accuracy and quality. (using AI)</li>
          <li>Unlimited ways to segment records to match your exact requirements using natural language. (using AI)</li>
          <li>Email drafts - highly targeted based on their business, and yours. Select from 5 best practice email prospecting methods (using AI)</li>
          <li>Every list entry is crawled in realtime to ensure up-to-date information with no dead links</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
