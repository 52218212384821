import './assets/satoshifonts/css/satoshi.css';
import React from 'react';
import Routes from './Routes';
import Footer from './components/Footer'
import { Provider } from 'react-redux';
import store from './redux/store'; // Import your updated Redux store
import ScrollToTop from './components/scrollToTop'; // Make sure to import your ScrollToTop component

const App = () => {

  // const isAuthenticated = true; // Example: Set this based on your authentication state
  // console.log("react app url is " + process.env.REACT_APP_WFE_URL)

  return (
    <Provider store={store}>
      <div className="App">
        <ScrollToTop />
        <Routes />
        <Footer />
      </div>
    </Provider>
  );
};

export default App;
