import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Badge, Button, Modal } from 'react-bootstrap';
import SidebarMenu from '../components/SidebarMenu';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, connect } from 'react-redux';
import { HiRefresh } from "react-icons/hi";
import { MdSmsFailed } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { refreshSubscriptions } from '../redux/actions';
import { fetchData } from '../helpers/api';

const BillingPage = (state) => {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
  const [monthly, setMonthly] = useState(false);
  const [annualPlan, setAnnualPlan] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const success = query.get('success');

  useEffect(() => {
    if (success === 'true') {
      setWaiting(true);
    } else if (success === 'false') {
      setWaiting(false);
    }
  }, [success]);

  useEffect(() => {
    // Check if any subscription has the desired plan ID  
    if (state.state.subscriptions) {
      const hasMonthlyPlan = state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === 'price_1Pcit3IMIEbbeViYRt7ncIiQ');
      setMonthly(hasMonthlyPlan);
    }
  }, [state.state.subscriptions]);


  useEffect(() => {
    // Check if any subscription has the desired plan ID  
    if (state.state.subscriptions) {
      const hasAnnualPlan = state.state.subscriptions.some(subscription => subscription.status === "active" && subscription.plan.id === 'price_1PcpMdIMIEbbeViY6327RfPG');
      setAnnualPlan(hasAnnualPlan);
    }
  }, [state.state.subscriptions]);

  const closeDialogue = () => {
    setWaiting(null);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      let url = process.env.REACT_APP_WFE_URL + "/database/refreshsubscriptions"
      const subs = await fetchData(url, {
        email: state.state.user.email
      }, {
        jwtToken: state.state.user.jwt
      });
      console.log(JSON.stringify(subs))
      dispatch(refreshSubscriptions(subs));
      setIsRefreshing(false);
    } catch (error) {
      console.error("Error during handleRefresh:", error);
      setIsRefreshing(false);
    }
  };

  const handleSubscription = async (priceId) => {
    console.log("handlesub");
    try {
      let url = process.env.REACT_APP_WFE_URL + "/stripe/create-checkout-session"
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "stripeCustomerId": state.state.user.stripeCustomerId, "priceId": priceId }),
      });
      const body = await response.json()
      window.location.href = body.url
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  };

  const PaymentFailed = () => (
    // Used to block the screen when async jobs are running  
    <React.Fragment>
      <div className="spinner-overlay">
        <div className="tick-container">
          <div>
            <MdSmsFailed className="tick-icon" />
          </div>
          <span className="tick-text">Purchase Cancelled</span>
          <br></br><br></br>
          <Button onClick={closeDialogue}>OK</Button>
        </div>
      </div>
    </React.Fragment>
  );

  const PaymentSuccess = () => (
    // Used to block the screen when async jobs are running  
    <React.Fragment>
      <div className="spinner-overlay">
        <div className="tick-container">
          <div>
            <TiTick className="tick-icon" />
          </div>
          <span className="tick-text">Purchase Succeeded</span>
          <div className="tick-text-sml">Your account balance will be updated in the next few minutes</div>
          <br></br><br></br>
          <Button onClick={closeDialogue}>OK</Button>
        </div>
      </div>
    </React.Fragment>
  );

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000); // Convert epoch to milliseconds  
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount / 100);
  };

  const handleCancelClick = (subscriptionId) => {
    setSubscriptionToCancel(subscriptionId);
    setShowCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setShowCancelDialog(false);
    setSubscriptionToCancel(null);
  };

  const handleConfirmCancel = async () => {
    if (subscriptionToCancel) {
      try {
        let url = process.env.REACT_APP_WFE_URL + "/stripe/cancel-subscription"
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriptionId: subscriptionToCancel }),
        });
        if (response.ok) {
          console.log('Subscription cancelled successfully');
          // Optionally refresh the subscriptions or perform other actions here  
        } else {
          console.error('Failed to cancel subscription');
        }
      } catch (error) {
        console.error('Error during subscription cancellation:', error);
      } finally {
        handleCloseCancelDialog();
      }
    }
  };

  return (
    <Container fluid>
      {waiting === true && <PaymentSuccess />}
      {waiting === false && <PaymentFailed />}
      <Row>
        <AdminHeader />
      </Row>
      <Row>
        <div className="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <Col md={2} lg={2} className="d-none d-md-block sidebarColumn">
          <SidebarMenu />
        </Col>
        <Col className="adminBackground">
          <h1>Account & Billing</h1>
          <Row>
            <Col><div className="custom-form-label">Your email: {state.state.user.email}</div>
            <div className="custom-form-label">Your credit balance: {state.state.user.credits} credits 😇</div>
            </Col>
          </Row>
          
          <br></br><br></br>

          
          <h3>Your subscriptions</h3>
          <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
            <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
          <br /><br />
          <Row>
            {(!state.state.subscriptions) ? (
              <p>No active subscriptions found, try hitting refresh to re-sync.</p>
            ) : (
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Renewal Date</th>
                    <th>Plan Amount</th>
                    <th>Plan Currency</th>
                    <th>Plan Interval</th>
                    <th>ID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.state.subscriptions.map((subscription, index) => (
                    <tr key={index}>
                      <td>
                        {subscription.status === 'active' ? (
                          <Badge bg="success">Active</Badge>
                        ) : (
                          <Badge bg="light" text="dark">{subscription.status}</Badge>
                        )}
                      </td>
                      <td>{formatDate(subscription.current_period_end)}</td>
                      <td>{formatCurrency(subscription.plan.amount, subscription.plan.currency)}</td>
                      <td>{subscription.plan.currency.toUpperCase()}</td>
                      <td>{subscription.plan.interval}</td>
                      <td>{subscription.id}</td>
                      <td>
                        {subscription.status !== 'canceled' && (
                          <Badge className="delete-badge" onClick={() => handleCancelClick(subscription.id)}><FaRegTrashAlt /> Cancel</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <Col>
              {/* Additional plans can be added here */}
            </Col>
          </Row>
          <Row>
            <p></p><p></p>
            <h3>Available plans</h3>
            <Col>
              <Card className="card-dashboard">
                <Card.Body>
                  <Card.Title>{monthly ? <div><Badge bg="success">Active</Badge></div> : null}Monthly Plan</Card.Title>
                  <Card.Text>
                    USD $19/month
                    <br></br><br></br>
                    Includes 50 credits per month to use across all services.
                  </Card.Text>
                  {monthly ? null : <button onClick={() => handleSubscription(process.env.REACT_APP_STRIPE_MONTHLY)}>Subscribe</button>}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="card-dashboard">
                <Card.Body>
                  <Card.Title>{annualPlan ? <div><Badge bg="success">Active</Badge></div> : null}Annual Plan</Card.Title>
                  <Card.Text>
                    USD $190/year
                    <br></br><br></br>
                    Includes 50 credits per month to use across all services. 2 months free when purchased annually.
                  </Card.Text>
                  {annualPlan ? null : <button onClick={() => handleSubscription(process.env.REACT_APP_STRIPE_ANNUAL)}>Subscribe</button>}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Modal show={showCancelDialog} onHide={handleCloseCancelDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel your subscription?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancelDialog}>
            Don't Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(BillingPage);  
