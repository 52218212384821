//// CURRENT JOB
export const setChangedData = (data) => ({
  type: 'SET_CHANGED_DATA',
  payload: data, // Pass the new JSON array as payload
});

export const setSearchQuery = (data) => ({
  type: 'SET_SEARCH_QUERY',
  payload: data, // Pass the new JSON array as payload
});

export const refreshJobs = (data) => ({
  type: 'REFRESH_JOBS',
  payload: data, // Pass the new JSON array as payload
});

export const refreshSubscriptions = (data) => ({
  type: 'REFRESH_SUBSCRIPTIONS',
  payload: data, // Pass the new JSON array as payload
});

export const updateSpecificJob = (data) => ({
  type: 'UPDATE_SPECIFIC_JOB',
  payload: data, // Pass the new JSON array as payload
});


//// SINGLESITE
export const refreshSingleSite = (data) => ({
  type: 'REFRESH_SINGLE_SITE',
  payload: data, // Pass the new JSON array as payload
});

//// LISTUPLOAD
export const refreshListUpload = (data) => ({
  type: 'REFRESH_LIST_UPLOAD',
  payload: data, // Pass the new JSON array as payload
});


//// USER
export const setJWT = (data) => ({
  type: 'SET_JWT',
  payload: data, // Pass the new JSON array as payload
});

export const updateUserBio = data => ({
  type: 'UPDATE_USER_BIO',
  payload: data
})

export const updateUserCredits = data => ({
  type: 'UPDATE_USER_CREDITS',
  payload: data
})

export const updateUserLanguage = data => ({
  type: 'UPDATE_USER_LANGUAGE',
  payload: data
})

export const logout = () => ({  
  type: 'LOGOUT',  
});  
