// store.js  
  
import { configureStore } from '@reduxjs/toolkit';  
import myReducer from './myReducer';  
import { loadState, saveState } from './localStorage';  
  
const preloadedState = loadState();  
  
const store = configureStore({  
  reducer: {  
    myReducer,  
  },  
  preloadedState,  
});  
  
store.subscribe(() => {  
  saveState(store.getState());  
});  
  
export default store;  
