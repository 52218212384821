import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { RiMenuSearchLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { TbWorldWww } from "react-icons/tb";

const DashboardPage = () => {
  
  return (
    <Container fluid>
      <Row>
        <AdminHeader />
      </Row>
      <Row>
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <Col md={2} lg={2} className="d-none d-md-block sidebarColumn">
          <SidebarMenu />
        </Col>
        <Col className="adminBackground">
          <h1>Dashboard</h1>
          <Row>
            <Col lg={12} xl={4}>
              <NavLink to="/singlesite" style={{ textDecoration: 'none' }}>
                <Card className="card-dashboard">
                  <Card.Body>
                    <Card.Title><TbWorldWww className='icon-xl' /> Prospect a Site</Card.Title>
                    <Card.Text>
                      Have a business you want to prospect?
                      <br></br><br></br>
                      Simply enter the URL, and Jummbo will generate a prospecting email, call script, and organization summary in under a minute!
                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
            <Col lg={12} xl={4}>
              <NavLink to="/listupload" style={{ textDecoration: 'none' }}>
                <Card className="card-dashboard">
                  <Card.Body>
                    <Card.Title><FiUploadCloud className='icon-xl' /> Upload a List</Card.Title>
                    <Card.Text>
                    Already have a list of URLs to prospect?
                      <br></br><br></br>
                      Let Jummbo handle the hard work. For each URL, we'll research the site, craft an outreach email, and generate a call script for you.
                      <br></br>

                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
            <Col lg={12} xl={4}>
              <NavLink to="/jobs" style={{ textDecoration: 'none' }}>
                <Card className="card-dashboard">
                  <Card.Body>
                    <Card.Title><RiMenuSearchLine className='icon-xl' /> Search & Build a List</Card.Title>
                    <Card.Text>
                    Looking for more organizations to sell to? 
                      <br></br><br></br>
                      Just specify the industry and location, and Jummbo will find potential prospects for you. We'll take care of the rest.
                      <br></br>

                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardPage;