// LegalPage.js
import React from 'react';

const LegalPage = () => {
  return (
    <div>
      <h1>Legal Information</h1>
      <p>Terms and conditions, privacy policy, etc..</p>
    </div>
  );
};

export default LegalPage;
