import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Table, Form, Button, Badge } from 'react-bootstrap';
import SidebarMenu from '../components/SidebarMenu';
import AdminHeader from '../components/AdminHeader';
import { useDispatch, connect } from 'react-redux';
import BuilderProgress from '../components/BuilderProgress';
import MobileMenu from '../components/MobileMenu';
import { fetchData } from '../helpers/api'; // Import the fetchData function
import { refreshJobs } from '../redux/actions'; // Import your action creator
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImSpinner9 } from "react-icons/im";
import { FaAnglesRight } from "react-icons/fa6";
import { HiRefresh } from "react-icons/hi";

const Step2Page = (allstate) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigate function
  const { id } = useParams();
  const [waiting, setWaiting] = useState(false);


  const Spinner = () => (
    //used to block the screen when async jobs are running
    <div className="spinner-overlay">
      <ImSpinner9 className="spinner-icon" />
    </div>
  );

  const navToPage3 = async (jobId) => {
    navigate(`/builder/step3/${id}`);
  }

  const toggleSelectedColumn = async (jobId, fieldName, jobsState) => {
    const allJobs = jobsState.map((job) => {
      if (job._id === jobId) {
        const updatedColumns = job.Columns.map(col => {
          if (col.fieldName === fieldName) {
            return {
              ...col, // Create a new column object  
              selected: !col.selected // Toggle the selected value immutably  
            };
          }
          return col; // Return the column as is if it doesn't match the fieldName  
        });

        return {
          ...job, // Create a new job object  
          Columns: updatedColumns // Use the updated columns array  
        };
      }
      return job; // No changes for other jobs  
    });

    // console.log("All jobs are: " + JSON.stringify(allJobs));
    // Uncomment the next line if you need to dispatch the updated jobs  
    await dispatch(refreshJobs(allJobs));
  };




  const generateColumns = async (jobId, jobsState) => {
    let requestdata = { "id": jobId }
    setWaiting(true);
    let url = process.env.REACT_APP_WFE_URL + "/jobs/generatecolumns"
    const generatedColumns = await fetchData(url, requestdata, { "jwtToken": allstate.allstate.user.jwt })
    // console.log("generateColumns reply is " + JSON.stringify(generatedColumns))

    // // Find the job with the specified ID, as we will add the columns into it now
    const uj = jobsState.map((job) => {
      if (job._id === jobId) {
        // Create a new object with updated properties
        return {
          ...job,
          Columns: generatedColumns
        };
      }
      return job; // No changes for other jobs
    });
    await dispatch(refreshJobs(uj));
    setWaiting(false);
    return generatedColumns
  }


  return (
    <Container fluid>
      {waiting && <Spinner />}
      <Row>
        <AdminHeader />
      </Row>
      <Row>
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <Col md={2} lg={2} className="d-none d-md-block sidebarColumn">
          <SidebarMenu />
        </Col>
        <Col className="adminBackground" md={10} lg={10}>
          <BuilderProgress id={id} stage='2' />
          <h2>Review Fields</h2>
          {
            allstate.allstate.jobs.map((job, index) => (
              <React.Fragment>

                {
                  job._id === id ? (
                    <React.Fragment>
                      <div className="adminContainer">
                        
                        {/* <Row>
                          <span onClick={() => generateColumns(job._id, allstate.allstate.jobs)}><HiRefresh />Refresh column suggestions</span><br></br><br></br>
                        </Row> */}
                        {job.Columns && (
                          <React.Fragment>
                            <h4><IoIosCheckmarkCircle /> Auto-Selected Fields</h4>
                            {/* Here's what we have pre-selected to go in your list. Click a box to deselect it from being included.<br></br> */}
                            <React.Fragment>
                              {job.Columns.map((column, index) => (
                                <React.Fragment key={index}>
                                  {column.selected ?
                                    <React.Fragment>
                                      {/* <Badge className="field-badge-selected" onClick={() => toggleSelectedColumn(job._id, column.fieldName, allstate.allstate.jobs)}> */}
                                      <Badge className="field-badge-selected">
                                        <span style={{ fontFamily: "Satoshi-Medium" }}>{column.fieldName}</span>
                                        <br></br>eg: {Array.isArray(column.values) ? column.values.join(', ') : column.values} 
                                      </Badge>
                                    </React.Fragment>
                                    :
                                    null
                                  }
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                            <br></br><br></br><br></br>
                            {/* <h4>Additional Available Field Types</h4>
                            Click a box to include it in your list.<br></br>
                            <React.Fragment>
                              {job.Columns.map((column, index) => (
                                <React.Fragment key={index}>
                                  {column.selected ?
                                    null
                                    :
                                    <React.Fragment>
                                      <Badge
                                        className="field-badge-unselected"
                                        onClick={() => toggleSelectedColumn(job._id, column.fieldName, allstate.allstate.jobs)}
                                      >
                                        <span style={{ fontFamily: "Satoshi-Medium" }}>
                                          {column.fieldName} (
                                          {column.fieldType === 'Boolean' ? 'Yes or No' : column.fieldType})
                                        </span>
                                        <br />
                                        {column.fieldDescription}
                                        <br />
                                        eg: {Array.isArray(column.values) ? column.values.join(', ') : column.values} 
                                      </Badge>
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              ))}
                            </React.Fragment> */}
                          </React.Fragment>
                        )}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => navToPage3(job._id)}>
                          Continue <FaAnglesRight />
                        </Button>
                      </div>
                    </React.Fragment>

                  ) : null}

              </React.Fragment>
            ))
          }

        </Col>
      </Row >
    </Container >
  );
};


const mapStateToProps = (state) => {
  return {
    allstate: state.myReducer
  };
};

export default connect(mapStateToProps)(Step2Page);