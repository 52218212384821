import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import SidebarMenu from '../components/SidebarMenu';
import AdminHeader from '../components/AdminHeader';
import Step1 from '../components/Step1'
import { useDispatch, connect } from 'react-redux';
import BuilderProgress from '../components/BuilderProgress';
import MobileMenu from '../components/MobileMenu';

const BuilderPage = () => {
  const { id } = useParams();
  return (
    <Container fluid>
      <Row>
        <AdminHeader />
      </Row>
      <Row>
      <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <Col md={2} lg={2} className="d-none d-md-block sidebarColumn">
          <SidebarMenu />
        </Col>
        <Col className="adminBackground">
        <BuilderProgress id={id} stage='1' />
          <Step1 id={id}/>         
        </Col>
      </Row>
    </Container>
  );
};



const mapStateToProps = (state) => {
  return {
    jobs: state.myReducer.jobs
  };
};

export default connect(mapStateToProps)(BuilderPage);