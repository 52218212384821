// Sidebar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { IoIosHome, IoIosColorWand, IoIosCard } from "react-icons/io";
import { RiMenuSearchLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { connect } from 'react-redux';
import { TbWorldWww } from "react-icons/tb";
import { IoChatbubbleOutline } from "react-icons/io5";


const SidebarMenu = (user) => {

  const email = user.user.email
  const username = email.split("@")[0];

  // Get the current route path
  const currentRoute = window.location.pathname;

  // Define a function to check if the current route starts with "/builder"
  const isBuilderRoute = currentRoute.startsWith('/builder');


  return (
    <div className="sidebar">
      <Nav defaultActiveKey="/dashboard" >
        <NavLink to="/dashboard" activeClassName="active">
          <IoIosHome className='icon-xl' />
          <br />Dashboard
        </NavLink>
        <NavLink to="/singlesite" activeClassName="active">
          <TbWorldWww className='icon-xl' />
          <br />Single Site
        </NavLink>
        <NavLink to="/listupload" activeClassName="active">
          <FiUploadCloud className='icon-xl' />
          <br />List Upload
        </NavLink>
        <NavLink to="/jobs" activeClassName="active">
          <RiMenuSearchLine  className='icon-xl' />
          <br />Search & Build
        </NavLink>
        {/* Conditionally render Builder link */}
        {isBuilderRoute ? (
          <NavLink>
            <IoIosColorWand className='icon-xl' />
            <br />Builder
          </NavLink>
        ) : (
          null
        )}
        <NavLink to="/billing" activeClassName="active">
          <IoIosCard className='icon-xl' />
          <br />Billing
        </NavLink>
        <NavLink to="/settings" activeClassName="active">
          < IoMdSettings className='icon-xl' activeClassName="active" />
          <br />Settings
        </NavLink>
        <NavLink to="mailto:support@jummbo.ai" activeClassName="active">
          < IoChatbubbleOutline  className='icon-xl' activeClassName="active" />
          <br />Support
        </NavLink>
      </Nav>
    </div >
  );
};



const mapStateToProps = (state) => {
  return {
    user: state.myReducer.user
  };
};

export default connect(mapStateToProps)(SidebarMenu);

// export default SidebarMenu;