import React, { useState } from 'react';
import { Row, Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { IoIosCog, IoIosHome, IoIosColorWand, IoIosCard, IoIosList } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { connect } from 'react-redux';
import { GiHamburgerMenu } from "react-icons/gi";

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (
    <React.Fragment>
      <div className="nav-link">
        <Row onClick={toggleMenu}>
          <GiHamburgerMenu className='mob-menu-icon'/>
        </Row>
        {
          isMenuOpen && (
            <React.Fragment>
              <div className="mob-menu-item">
              <NavLink to="/dashboard">
                {/* <IoIosHome className='mob-menu-icon' /> */}
                Dashboard
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="/singlesite" activeClassName="active">
                {/* <IoIosList className='mob-menu-icon' /> */}
                Single Site
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="/listupload" activeClassName="active">
                {/* <IoIosList className='mob-menu-icon' /> */}
                List Upload
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="/jobs" activeClassName="active">
                {/* <IoIosList className='mob-menu-icon' /> */}
                Build Lists
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="/billing" activeClassName="active">
                {/* <IoIosCard className='mob-menu-icon' /> */}
                Billing
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="/settings" activeClassName="active">
                {/* < FaUserCircle className='mob-menu-icon' /> */}
                Settings
              </NavLink>
              </div>
              <div className="mob-menu-item">
              <NavLink to="mailto:jummbo.ai" activeClassName="active">
                {/* < FaUserCircle className='mob-menu-icon' /> */}
                Support
              </NavLink>
              </div>
            </React.Fragment>
          )
        }
      </div>
    </React.Fragment>
  )
};



const mapStateToProps = (state) => {
  return {
    user: state.myReducer.user
  };
};

export default connect(mapStateToProps)(MobileMenu);

// export default SidebarMenu;