// components/NotFound.js  
import React from 'react';  
  
const NotFound = () => {  
  return (  
    <div style={{ textAlign: 'center', marginTop: '50px' }}>  
          <br></br><br></br><br></br><br></br><br></br>
      <h1>404</h1>  
      <p>Page Not Found</p>  
      <br></br><br></br><br></br><br></br><br></br>
    </div>  
  );  
};  
  
export default NotFound;  
