import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions';
import { clearState } from '../redux/localStorage';

const LogoutButton = () => {
    const navigate = useNavigate(); // Get the navigate function
    const dispatch = useDispatch();


    const handleLogout = () => {
        dispatch(logout());
        clearState();
        navigate(`/login`); // Redirect to login page or any other page  
    };

    return (
        <span onClick={handleLogout}>Logout</span>
    );
};

export default LogoutButton;  