// api.js

// Fetch data from an HTTPS endpoint
export const fetchData = async (url, requestData, jwt) => {
  // console.log("fetching with " + JSON.stringify(requestData) + JSON.stringify(jwt))
  let headerauth = {};
  if (jwt) {
    headerauth = {
      'Authorization': `Bearer ${jwt.jwtToken}`,
      'Content-Type': 'application/json'
    };
    // console.log("header including auth is " + JSON.stringify(headerauth));
  } else {
    headerauth = {
      'Content-Type': 'application/json'
    };
    // console.log("header is " + JSON.stringify(headerauth));
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headerauth,
      body: JSON.stringify(requestData), // Convert data to JSON
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const data = await response.json(); // Parse the response
    // console.log("api received " + data)
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Handle errors gracefully
  }
};
