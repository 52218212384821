import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Card, Form, FormControl, FormGroup, FormLabel, Button, Badge, CloseButton } from 'react-bootstrap';
import SidebarMenu from '../components/SidebarMenu';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api';
import { HiRefresh } from "react-icons/hi";
import { refreshListUpload, updateUserCredits } from '../redux/actions';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCloudDownload } from "react-icons/io";

const ListUploadPage = (state) => {
    const dispatch = useDispatch();
    const [urls, setUrls] = useState([]);
    const [currentInput, setCurrentInput] = useState(''); // State for the current input  
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [notification, setNotification] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        // This effect will run whenever the urls array changes  
        // console.log("urls is now " + urls);
    }, [urls]);

    const handleInputChange = (e) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addUrls(currentInput.trim());
        }
    };

    const handleExpandClick = (index) => {
        setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
    };

    // Function to add multiple URLs  
    const addUrls = (input) => {
        const newUrls = input.split(/[\n,]+/).map(url => url.trim()).filter(url => url && !urls.includes(url));
        if (newUrls.length) {
            setUrls([...urls, ...newUrls]);
            setCurrentInput('');
        }
    };

    const removeUrl = (urlToRemove) => {
        setUrls(urls.filter(url => url !== urlToRemove));
    };

    const renderStatus = (bulklist) => {
        switch (bulklist.progress.status) {
            case 'Submitted':
                return 'Awaiting Processing';
            case 'Processing':
                return `${bulklist.records.length} of ${bulklist.urls.length} Processed`;
            case 'Completed':
                return <Badge className="download-badge" onClick={() => {
                    const link = document.createElement('a');
                    link.href = `https://jummbo.blob.core.windows.net/downloads/${bulklist.csv}`;
                    link.download = "jummbo.csv";  // Optional: This sets the downloaded filename  
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }} ><IoIosCloudDownload /> Download</Badge>
            default:
                return 'Unknown Status';
        }
    };

    const handleRefresh = async () => {
        let url = process.env.REACT_APP_WFE_URL + "/database/findalldocsbyuserid"
        console.log("refresh! to " + url);
        setIsRefreshing(true);
        try {
            const allListUploads = await fetchData(url, {
                collection: "listupload",
                userId: state.state.user.id
            }, {
                jwtToken: state.state.user.jwt
            });
            dispatch(refreshListUpload(allListUploads));
            setIsRefreshing(false);
        } catch (error) {
            console.error("Error during handleRefresh:", error);
            setIsRefreshing(false);
        }
    };


    const handleSubmit = async (event) => {
        setIsProcessing(true);
        event.preventDefault();

        //update credit balance
        let deductCreditValue = parseInt(urls.length);
        let newCredits = state.state.user.credits - deductCreditValue
        await dispatch(updateUserCredits(newCredits))
        let url = process.env.REACT_APP_WFE_URL + "/database/updateuser"
        fetchData(url, { "_id": state.state.user.id, "credits": newCredits }, { "jwtToken": state.state.user.jwt })

        //now upload list
        var newListUpload = {
            "urls": urls,
            "userId": state.state.user.id,
            "language": state.state.user.language,
            "specialInstructions": specialInstructions,
            "progress": { "status": "Submitted", "runDate": null },
            "result": { "backgroundInfo": "", "callscript": "", "prospectingemail": "" }
        }
        console.log("Submitting" + JSON.stringify(newListUpload));
        //update state
        const updatedArray = [...state.state.listupload];
        // const updatedArray = [];
        // // Push the new element to the copied array  
        updatedArray.push(newListUpload);
        // Dispatch the action to refresh the array  
        dispatch(refreshListUpload(updatedArray));
        console.log("submitting " + JSON.stringify(newListUpload))
        let url2 = process.env.REACT_APP_WFE_URL + "/listupload/submit"
        await fetchData(url2, newListUpload, { "jwtToken": state.state.user.jwt });
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
        setUrls([])
        setIsProcessing(false);
    };


    return (
        <Container fluid>
            <Row>
                <AdminHeader />
            </Row>
            <Row>
                <div className="col-md-3 d-block d-md-none">
                    <MobileMenu />
                </div>
                <Col md={2} lg={2} className="d-none d-md-block sidebarColumn">
                    <SidebarMenu />
                </Col>
                <Col className="adminBackground">
                    <div>
                        <h1>Upload & Enrich a List</h1>
                    </div>
                    <Row>
                        <Col><br /><br />
                            <p>Do you have a large amount of organisations you'd like to research and prospect?<br /></p>
                            <p>Enter all the URLs in the box below, we'll research each site, and for each, build for you:</p>
                            <li>a prospecting email</li>
                            <li>a call script</li>
                            <li>a short summary of the organisation to save you research time</li><br></br>
                            <p>You'll then receive your completed list back in a CSV via email.</p>
                        </Col>
                        <Col>
                            <Card className="card-dashboard">
                                <Card.Body>
                                    <Card.Title><span className='generate-text-selected'><FaCheckCircle /></span> Your Business Info</Card.Title>
                                    <Card.Text>
                                        You've already told us about your business, so each call script and prospecting email will be highly targeted.
                                        <br /><br />
                                        <Link to={`/settings`} className='hyperlink'>Edit</Link>
                                        <br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <h5>List to Prospect:</h5>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup controlId="url">
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', padding: '5px' }}>
                                {urls.map((url, index) => (
                                    <Badge key={index} variant="primary" className="field-badge-selected">
                                        {url}
                                        <CloseButton onClick={() => removeUrl(url)} style={{ marginLeft: '5px' }} />
                                    </Badge>
                                ))}

                            </div>
                            <FormControl
                                as="textarea"
                                rows={3}
                                placeholder="Paste in each URL, separated via a comma or new line"
                                value={currentInput}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                disabled={isProcessing}
                                style={{ flex: '1', border: 'none', outline: 'none' }}
                            />
                        </FormGroup>
                        <FormGroup controlId="specialInstructions">
                            <FormLabel>Special Instructions</FormLabel>
                            <FormControl
                                as="textarea"
                                placeholder="Special Instructions"
                                value={specialInstructions}
                                rows={4}
                                onChange={(e) => setSpecialInstructions(e.target.value)}
                                disabled={isProcessing}
                            />
                        </FormGroup>
                        <br />
                        Credits required: {urls.length}<br></br>
                        Your balance: {state.state.user.credits}<br></br><br></br>
                        <Button type="submit" disabled={isProcessing || urls.length === 0 || urls.length > state.state.user.credits}>
                            {isProcessing ? 'Processing...' : 'Research Urls'}
                        </Button>
                        {(urls.length > state.state.user.credits) ? <div>Insufficient credits to continue, try reducing your list</div> : null}
                    </Form>
                    {notification && <div className="notification"><IoCheckmarkCircleSharp /> Submitted URLs successfully!</div>}
                    <br /><br />
                    <div>
                        <h3>Your requests</h3>
                        <span onClick={handleRefresh} style={{ cursor: 'pointer' }}>
                            <HiRefresh className={isRefreshing ? 'icon-left-spinning' : 'icon-left'} /> Refresh</span>
                        <br /><br />
                        <Table bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Web addresses</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.state.listupload.map((bulklist, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td onClick={() => handleExpandClick(index)}>
                                                <span className={expandedIndex === index ? 'regular-bold' : ''}>
                                                    {bulklist.urls.length} urls submitted
                                                </span>
                                            </td>
                                            <td>
                                                {renderStatus(bulklist)}
                                            </td>
                                        </tr>
                                        {expandedIndex === index && (
                                            <tr>
                                                <td colSpan="2">
                                                    {bulklist.urls.join(', ')}
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        state: state.myReducer
    };
};

export default connect(mapStateToProps)(ListUploadPage);  
